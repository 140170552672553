@use "./functions" as *;

$baseline: 1.25;
$primary-font: "Noto Sans", "arial", "helvetica", sans-serif;
$font-default: to-rem($font-root);

/* FIXED PIXELS */
$size-xxs: 2px;
$size-xs: calc(2 * $size-xxs); // 4px
$size-sm: calc(2 * $size-xs); // 8px
$size-md: calc(2 * $size-sm); // 16px
$size-lg: calc(6 * $size-xs); // 24px
$size-xl: calc(2 * $size-md); // 32px
$size-xxl: calc(2 * $size-xl); // 64px
$size-xxxl: calc(2 * $size-xxl); // 128px

/* PADDING */
$ratio-xxs: to-rem(4px);
$ratio-xs: to-rem(6px);
$ratio-sm: to-rem(8px);
$ratio-md: to-rem(12px);
$ratio-lg: to-rem(14px);
$ratio-xl: to-rem(16px);
$ratio-xxl: to-rem(24px);
$ratio-xxxl: to-rem(35px);
$page-padding-xxl: to-rem(40px);

/* OTHERS */
$button-height: 48px;
$cta-round-size: 54px;
$md-badge-height: 36px;
$lg-badge-height: 80px;
$button-width: 165px;
$default-gap: 8px;
$md-menu-width: 316px;
$lg-menu-width: 355px;

// general app display
$topbar-height: 54px;

/* ICONS */
$icon-xs: $size-md;
$icon-sm: 20px;
$icon-md: $size-lg;
$icon-lg: 34px;
$icon-xl: 40px;

/* BUTTONS - HEIGHT */
$btn-height-default-lg: $size-lg;
$btn-height-default-sm: 20px;
$btn-height-default-xs: 16px;
$btn-height-button-cta-lg: 56px;
$btn-height-button-cta-sm: 44px;

/* ICON BUTTON */
$icon-button-lg: 48px;
$icon-button-sm: 36px;
$icon-button-xs: $size-lg;

/* BORDER-RADIUS */
$radius-xs: $size-sm;
$radius-sm: 14px;
$radius-md: $size-md;
$round-radius: 9999px;

/* BLUR */
$blur-sm: blur(17.5px);
$blur-md: blur(25px);
$blur-lg: blur(32.5px);

/* FIXED VIEW WIDTH */
$view-width-xs: 1vw;

/* FONT WEIGHT */
$regular: 400;
$semi-bold: 600;
$bold: 700;

/* PERCENT */
$gap-sm: 5%;

/* BREAKPOINTS */
$breakpoints: (
  xsmall: 480px,
  small: 736px,
  medium: 980px,
  large: 1280px,
  xlarge: 1620px,
  xxlarge: 1690px,
);

/* ELEVATION */
$elevations: (
  page-title: 10,
  page-header:8,
  panel: 15,
  menu: 18,
  navigation: 50,
  modal: 99,
  dropdown: 98,
  background: -1,
  layers: 100,
  top: 9999,
);