@use "@evc/core/index" as *;

html,
body {
  position: fixed;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;

  * {
    box-sizing: border-box;
  }
}

button {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

.app .app__platform {
  .user-menu__user {
    display: none;
  }
}